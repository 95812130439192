import Vue from 'vue'
import App from './App.vue'
import {BtChart} from 'bt-design'

import router from "./router/index.js"

router.afterEach(() => {
  setTimeout(()=>{
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?a46ae55be1108969356ab763c0ec0c69";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },0);
  setTimeout(()=>{
    (function() {
      var img = document.createElement("img");
      img.src = "//webapi.wx.jiangdu.gov.cn/inta/assets/viewWeb?" + new Date().getMilliseconds();
      img.style = "display:none;"
      document.body.appendChild(img)
    })();
  },100);
});

import {Tabs,TabPane,Table,TableColumn,Row,Col,Dropdown,DropdownItem,DropdownMenu,Form,FormItem,Select,Option,Input,Button,Loading,Dialog,Tooltip,MessageBox} from 'element-ui'
import './styles/element-variables.scss'
import "./styles/base.css"
Vue.use(BtChart)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.component(MessageBox.name,MessageBox)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
